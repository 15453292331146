/* CustomModal */
/* TODO max width and height */
.custom-modal {
    position: absolute;
    display: flex;
    width: 80%;
    height: 90%;
    max-width: 960px;
    max-height: 720px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background-color: #101525;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 25px;
    outline: none;
}

.custom-modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 1000;
}

.wider {
    width: 95%;
    height: 95%;
    max-width: 1440px;
    max-height: 840px;
}

.medium {
    height: 90%;
    max-width: 960px;
    max-height: 720px;
    padding: 15px;
    overflow-y: scroll;
}

.small {
    height: 70%;
    max-width: 450px;
    max-height: 420px;
    padding: 15px;
    padding-left: 25px;
    overflow-y: scroll;
}

.small-short {
    height: 70%;
    max-width: 480px;
    max-height: 270px;
    padding: 15px;
    padding-left: 25px;
    overflow-y: scroll;
}

.small-tall {
    height: 90%;
    max-width: 480px;
    max-height: 720px;
    padding: 12px;
    padding-left: 15px;
    /* overflow-y: scroll; */
}

.mini {
    height: 150px;
    width: 480px;
    padding: 15px;
    padding-left: 25px;
}

.upload-image {
    width: fit-content;
    height: fit-content;
    padding: 32px;
}

.preview-modal {
    background-color: transparent;
    width: fit-content;
    height: fit-content;
    padding: 5px;
}

.progress-bar {
    width: fit-content;
    height: fit-content;
    padding: 20px 30px;
}

.export-wide {
    width: 900px;
    height: 650px;
    padding: 12px 25px;
    transition: width .25s linear;
}

.export-narrow {
    width: 450px;
    height: 650px;
    padding: 12px 25px;
    transition: width .25s linear;
}

/* ConfirmModal */
.confirm-modal-cont {
    margin: 0 auto;
}

.confirm-modal {
    position: absolute;
    display: block;
    width: 50%;
    max-width: 720px;
    height: 240px;
    padding: 12px;
}

.confirm-icon-message {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirm-icon {
    margin: 12px 0;
    font-size: 55px;
    color: var(--dark-yellow);
}

.confirm-title {
    font-size: 1.5em;
    margin-bottom: 7px;
    text-align: center;
}

.confirm-modal-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 7px;
}

.transparent-modal {
    height: fit-content;
    width: fit-content;
    padding: 10px;
    border: none;
    background-color: transparent;
}

@media screen and (max-width: 768px) {
    .confirm-icon-message {
        font-size: 11px;
    }
    .confirm-icon {
        margin: 7px 0;
        font-size: 42px;
    }
}

@media screen and (max-width: 480px) {
    .custom-modal {
        width: 95%;
    }
    .medium {
        height: 97%;
        max-width: unset;
        max-height: unset;
        padding: 15px 5px;
    }
    .confirm-icon-message {
        font-size: 10px;
    }
}