.exports-and-shares {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.exports-and-shares-filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 20px;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.exports-table-filter,
.shares-table-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    cursor: pointer;
}

.exports-table-filter.active,
.shares-table-filter.active {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    font-weight: 500;
}

.exports-and-shares-filter div:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.exports-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.exports-table {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3px;
    height: calc(100% - 125px);
    margin-bottom: 15px;
}

.exports-table div {
    text-align: center;
}

.export-clip-single {
    width: 100%;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 12px;
    background-color: rgba(0,0,0,.35);
    border-radius: 3px;
}

.export-clip-single.expired {
    color: rgba(255, 255, 255, 0.5);
}

.export-submitted {
    width: 10%;
}

.export-source {
    width: 25%;
}

.export-preset {
    width: 7%;
}

.export-creator {
    width: 15%;
}

.export-expires {
    width: 12%;
}

.export-size {
    width: 8%;
}

.export-duration {
    width: 8%;
}

.export-share {
    width: 8%;
}

.export-download {
    width: 8%;
}

.export-delete {
    width: 2%;
}

.export-delete.icon {
    color: white;
}

.export-processing {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 44%;
}

.export-source.data {
    font-weight: 600;
    padding: 0 5px;
}

.export-preset.data {
    max-width: 7%;
    padding: 0 5px;
    word-wrap: break-word;
}

.export-size.data,
.export-duration.data {
    color: var(--light-blue);
}

.export-clip-single:not(.expired) .export-size.data,
.export-clip-single:not(.expired) .export-duration.data {
    font-weight: 700;
}

.remove-export-btn {
    font-size: 13px;
    cursor: pointer;
}

.export-msg-cont {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--button-green);
}

.export-processing-time-info {
    color: rgba(255, 255, 255, .7);
}

.exports-table-info {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    gap: 3px;
    width: 45%;
    color: rgba(255, 255, 255, .7);
    margin-bottom: 15px;
    font-size: 12px;
}

.download-expired {
    color: var(--warning-text-red);
    font-weight: 500;
}

.exports-table-info svg {
    min-width: 14px;
    font-size: 14px;
}

.no-exports-table {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    color: rgba(255, 255, 255, .7);
    font-style: italic;
    margin: 25px 0;
}

/* HOVER */
.remove-export-btn:hover {
    color: var(--dark-yellow);
}
.table-header-sort:hover {
    color: white;
}
.exports-table-filter:not(.active):hover,
.shares-table-filter:not(.active):hover {
    filter: brightness(80%);
}