.subtitles-container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subtitles-table-container {
    width: 100%; 
    max-height: 400px; 
    overflow-y: auto;
}

.subtitles-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    margin: 0 auto;
    background-color: #2a2a2a00;
    color: white;
}

.subtitles-table thead tr:first-child th {
    width: auto;
    color: white;
    font-weight: bold;
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1.5px solid #595b68;
    border-top: 1.5px solid #595b68;
}

.subtitles-table th,
.subtitles-table td {
    border: none;
    padding: 12px 15px;
    text-align: left;
    background-color: #111625;
}

.subtitles-table th {
    background-color: #111625;
}

.subtitles-table tbody tr {
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
}

.subtitles-table tr:hover {
    background-color: #3a3a3a;
    color: #f4f4f4;
    cursor: pointer;
}

.subtitles-table tr:hover .edit-button {
    display: inline;
}

.edit-button {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: #0056b3;
}

.button-publish {
    width: auto;
    font-weight: 500;
}

.loading {
    color: #6c757d;
    font-style: italic;
}

.current-subtitle, .past-subtitle {
    color: #3BA2FF;
}

.future-subtitle {
    color: white;
}

.generation-error {
    color: #f44336;
    margin-top: 0.5rem;
}